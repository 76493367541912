<script>
import AskResume from "~/modules/ask/components/AskResume.vue";
import AskCart from "~/modules/ask/components/AskCart.vue";
import ShareCart from "~/modules/cart/components/ShareCart.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";
import { ElNotification } from 'element-plus'
import formHandler from "~/mixins/formHandler";
import Rental from "~/modules/ask/services/Rental";
import CancelEdit from "~/modules/ask/components/CancelEdit.vue";

function toIsoString(date) {
  let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
}

export default {
  name: "AskEdit",
  emits: ['changeCartItems'],
  ApiUrl: '/api/user/ask',
  mixins: [formHandler],
  components: {
    CancelEdit,
    ModalLayout, ShareCart,
    AskCart,
    AskResume
  },
  props: {
    availableCartQuantity: {
      type: Object,
      default: () => {
        return {}
      }
    },
    auth: {
      type: Object,
      default: () => {
        return {}
      }
    },
    ask: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      noCertificationPopup: false,
      lastChangeItem: null,
      cancelModal: false,
      cancelEdit: {
        reason: '0',
        otherReason: '',
        message: ''
      },
      edit: {
        
      }
    }
  },
  watch: {
    ask: {
      immediate: true,
      deep:true,
      handler (val) {
        // this.edit = val
        // console.log()
        if (val && !this.edit) {
          this.edit = JSON.parse(JSON.stringify(val))
        }
        if (val && this.edit) {
          let data = JSON.parse(JSON.stringify({
            ...this.edit,
            ...val
          }))
          this.edit = data
        }
      }
    },
  },
  computed: {
    formKey () {
      return 'Ask'
    },
    Model () {
      return Rental
    },
    isOwnerAndTenantNotCertified () {
      return this.ask && this.ask.proprietaire && this.ask.locataire && this.ask.proprietaire.id === this.auth.id && !this.ask.locataire.lightyShareCertifed
    },
    notificationAlertClasses () {
      if (this.step.includes('LOADING')) {
        return 'alert-info loading-background'
      }
      if (this.step.includes('CANCELED')) {
        return 'alert-danger'
      } else if (this.step.includes('WAITING')) {
        return 'alert-info'
      } else {
        return 'alert-success'
      }
    },
    notificationIconClasses () {
      if (this.step.includes('CANCELED')) {
        return 'fa fa-ban'
      } else if (this.step.includes('WAITING')) {
        return 'far fa-clock'
      } else {
        return 'far fa-check-circle'
      }
    },
    notificationStatus () {
      return this.step !== "LOADING" ? this.$t('manage-ask.notifications-status.' + this.userType + '.' + this.step) : ""
    },
    step () {
      if (!this.ask) {
        return 'LOADING'
      }
      if (this.ask && this.ask.canceled) {
        if (this.ask.locationProblem && this.ask.locationProblem.by && this.ask.locationProblem.by.id === this.ask.tenantId) {
          return 'CANCELED_BY_TENANT'
        } else if (this.ask.locationProblem && this.ask.locationProblem.by && this.ask.locationProblem.by.id === this.ask.ownerId) {
          return 'CANCELED_BY_OWNER'
        } else {
          return 'CANCELED'
        }
      } else {
        if (this.ask && this.ask.statutProprietaire === 1) {
          return 'WAITING_OWNER_VALIDATION'

        } else if (this.ask.statutProprietaire === 2 && this.ask.leftToPay > 0 && this.$moment(this.ask.departUnix,'X')) {
          return 'WAITING_TENANT_PAYMENT'
        } else if (this.ask.statutProprietaire === 2) {
          return 'WAITING_TENANT_CONFIRMATION'
        } else {
          return 'WAITING_DEPARTURE'
        }
        // if (this.$moment(this.ask.departUnix,'X').unix() < this.$moment().unix() && this.$moment(this.ask.finUnix,'X').unix() > this.$moment().unix()) {
        //   return 'IN_PROGRESS'
        // }
      }
    },
    templateClasses () {
      if (!this.ask) {
        return ''
      }
      if (this.userType === 'owner') {
        if (this.ask.statutProprietaire === 1) {
          return 'askTemplate owner'
        } else {
          return 'askTemplate tenant'
        }
        return 'askTemplate owner'
      } else {
        return 'askTemplate tenant'
      }
    },
    sinistreHref () {
      return this.ask && this.ask.panier && this.ask.proprietaire && this.ask.locataire ? "#tally-open=mZQpan&proprietaireNom=" + this.ask.proprietaire.nom + "&proprietairePrenom=" + this.ask.proprietaire.prenom + "&locatairePrenom=" + this.ask.locataire.prenom + "&locataireNom=" + this.ask.locataire.nom + "&locationValeur=" + this.ask.panier.valeurTotalMateriel + "&locationCaution=" + this.ask.panier.caution + "&locationId=" + this.ask.id + "&proprietaireMail=" + encodeURIComponent(this.ask.proprietaire.email) + "&locataireMail=" + encodeURIComponent(this.ask.locataire.email) + "&userLocationNum=" + this.ask.numeroLocation : "#"
    },
    procurationHref () {
      return this.ask && this.ask.proprietaire && this.ask.locataire ? "#tally-open=wogzMw&userId=" + this.ask.locataire.id + "&userNom=" + this.ask.locataire.nom + "&userPrenom=" + this.ask.locataire.prenom + "&userAdressePostale=" + this.adresseLocataire + "&userDateNaissance=" + this.$moment(this.ask.locataire.dateNaissance).format('DD-MM-YYYY') + "&userMail=" + encodeURIComponent(this.ask.locataire.email) + "&proprietaireMail=" + encodeURIComponent(this.ask.proprietaire.email) + "&userLocationId=" + this.ask.id + "&userLocationNum=" + this.ask.numeroLocation : ""
    },
    actionButtons () {
      if (!this.ask) {
        return []
      }
      if (this.userType === 'owner') {
        if (this.ask && this.ask.canceled && this.ask.locationProblem && this.ask.locationProblem.by && this.ask.locationProblem.by.id === this.ask.ownerId) {
          return [
            {
              label: "Revenir sur l'annulation",
              type: '',
              classes: 'btn btn-save',
              plain: true,
              loading: this.formLoading,
              onClick: () => {
                this.rollbackCancel()
              }
            }
          ]
        }
        if (this.ask && this.ask.statutProprietaire === 1) {
          return [
            {
              label: 'Accepter',
              type: '',
              classes: 'btn btn-save',
              plain: true,
              loading: this.formLoading && this.action === 'OwnerAccept',
              onClick: () => {
                this.sendOwnerAccept()
              }
            },
            {
              label: 'Refuser',
              type: '',
              classes: 'btn',
              plain: false,
              onClick: () => {
                this.cancelModal = true
              }
            }
          ]
        } else {
          return [
            {
              label: 'Messagerie',
              type: 'primary',
              classes: 'btn btn-blue',
              plain: true
            }
          ]
        }
      } else {
        if (this.ask && this.ask.statutProprietaire > 1 && this.ask.statutProprietaire < 3) {
          return [
            {
              label: 'Confirmer',
              type: '',
              classes: 'btn btn-save',
              plain: true,
              onClick: () => {
                this.tenantConfirmation()
              }
            }
          ]
        }
        return [
          {
            label: 'Messagerie',
            type: 'primary',
            classes: 'btn btn-blue',
            plain: true
          }
        ]
      }
      return []
    },
    actionButtonsFormatted () {
      return this.actionButtons.map((actionButton) => {
        if (typeof actionButton.onClick !== 'function') {
          actionButton.onClick = () => {
          }
        }
        return actionButton
      })
    },
    cancelInfoText () {
      if (this.ask && this.ask.typeAnnulation) {
        return this.$t('infos.cancel.description.' + this.ask.typeAnnulation.id)
      } else {
        return ''
      }
    },
    projectTitle () {
      return this.userType === 'tenant' ? 'Votre projet' : 'Le projet du locataire'
    },
    userType () {
      if (this.ask && this.ask.tenantId === this.ask.ownerId) {
        return this.$route.include('locataire') ? 'tenant' : 'owner'
      }
      return this.ask && this.auth && this.auth.id === this.ask.tenantId ? 'tenant' : 'owner'
    },
    startDateInfo () {
      if (!this.ask) {
        return false
      }
      const startMoment = this.$moment(this.ask.departUnix, 'X')
      return {
        day: startMoment.format('dddd'),
        date: startMoment.format('DD'),
        month: startMoment.format('MMM').toUpperCase()
      }
    },
    endDateInfo () {
      if (!this.ask) {
        return false
      }
      const endMoment = this.$moment(this.ask.finUnix, 'X')
      return {
        day: endMoment.format('dddd'),
        date: endMoment.format('DD'),
        month: endMoment.format('MMM').toUpperCase()
      }
    },
    isUpdatable () {
      return this.ask && this.ask.statutLocataire === 1 && this.ask.statutProprietaire === 1 && this.userType === 'owner'
    },
    cancelOwnerModalNoCartItemValid () {
      return this.edit && this.edit.panier && this.edit.panier.paniersAnnonce && this.edit.panier.paniersAnnonce.filter((cartItem) => {
        return cartItem.valide
      }).length === 0 //&& (this.ad && !this.ad.locationProblem)
    },
    secondaryActionsButtons () {
      const secondaryButtons = []
      if (this.ask && !this.ask.locationProblem) {
        secondaryButtons.push({
          label: this.$t('infos.actions.cancel'),
          onClick: () => {
            console.log('cancel')
            this.cancelModal = true
          },
          classes: "btn btn-primary"
        })
        if (this.ask && this.ask.statutProprietaire > 1) {
          secondaryButtons.push({
            label: this.$t('infos.actions.contract'),
            href: this.path('download_pdf_contrat_location', {'id': this.ask.id}),
            classes: "btn btn-primary"
          })
        }
        // if (this.ask.statutProprietaire > 1 && this.ask.locataire.id === this.auth.id) {
        secondaryButtons.push({
          label: this.$t('infos.actions.sinistre'),
          href: this.sinistreHref,
          classes: "btn btn-outline-primary"
        })
        // }
        if (this.ask && this.userType === 'tenant') {
          secondaryButtons.push({
            label: this.$t('infos.actions.procuration'),
            href: this.procurationHref,
            classes: "btn btn-outline-primary"
          })
        }
      }
      secondaryButtons.map((btn) => {
        if (!btn.onClick) {
          btn.onClick = (e) => {
            if (btn.href) {
            } else {
              e.preventDefault()
            }
          }
        }
        return btn
      })
      return secondaryButtons
    }
  },
  methods: {
    async afterFormSend () {
      if (this.action === 'OwnerCancel') {
        console.log('after send form')
        this.cancelModal = false
        this.cancelEdit = {
          reason: '0',
          otherReason: '',
          message: ''
        }
        ElNotification({
          title: "Votre demande a bien été annulée",
          type: 'success'
        })
      }
      if (this.action === 'RollbackOwnerCancel') {
        ElNotification({
          title: "Votre demande a bien été réactivée",
          type: 'success'
        })
      }
    },
    async tenantConfirmation () {
      if (!this.auth.lightyShareCertifed) {
        this.noCertificationPopup = true
      } else {
        window.location.href = this.path('rental_payment',{'id': this.ask.id})
      }
    },
    async sendOwnerAccept () {
      this.action = 'OwnerAccept'
      await this.sendForm(true, {
        accept: true
      }, '/api/user/ask', 'ownerAccept')
      this.action = null
    },
    async sendCancelation () {
      const reasonModeles = {
        "0": 'Matériel non disponible',
        "1": "Loueur indisponible",
      }
      let reason = null
      if (this.cancelEdit.reason === '2') {
        if (!this.cancelEdit.otherReason || this.cancelEdit.otherReason.length === 0) {
          ElNotification({
            title: 'Erreur',
            message: "Veuillez renseigner une raison",
            type: 'error'
          })
          return
        } else {
          reason = this.cancelEdit.otherReason
        }
      } else {
        reason = reasonModeles[this.cancelEdit.reason]
      }
      this.action = this.userType === 'owner' ? 'OwnerCancel' : 'TenantCancel'
      const postUrl = this.userType === 'owner' ? '/api/user/ask' : '/api/user/cancel-ask'
      await this.sendForm(true, {
        ...this.cancelEdit,
        type: 'Refuse',
        reason
      }, postUrl, 'locationProblem')
      this.action = null
    },
    async rollbackCancel () {
      this.action = 'RollbackOwnerCancel'
      await this.sendForm(true, {
        rollback: true
      }, '/api/user/ask', 'rollbackCancel')
      this.action = null
    },

    onCancelChange (edit) {
      console.log('change edit')
      console.log(edit)
      this.cancelEdit = edit
    },
    closeCancelModal () {
      this.cancelModal = false
      if (this.cancelOwnerModalNoCartItemValid) {
        let cartItem = this.lastChangeItem
        if (!this.lastChangeItem) {
          cartItem = this.ask.panier.paniersAnnonce[0]
        }
        cartItem.valide = true
        this.$refs.askCart.updateItem(cartItem)
      }
    },
    closeNoCertifiedPopup () {
      this.noCertificationPopup = false
    },
    transformHook (edit) {
      let newStartAt = this.$moment(this.ask.departUnix, 'X')
      let newEndAt = this.$moment(this.ask.finUnix, 'X')
      console.log(newStartAt)

      let paniersAnnonce = []
      if (edit && edit.panier && edit.panier.paniersAnnonce) {
        paniersAnnonce = edit.panier.paniersAnnonce.map((panierAnnonce) => {
          return {
            id: panierAnnonce.id,
            annonce_id: panierAnnonce.annonces.id,
            valide: panierAnnonce.valide,
            quantity: panierAnnonce.quantity
          }
        })
      }
      const data = {
        id: edit.id,
        panier: {
          startAt: newStartAt.toISOString(),
          endAt: newEndAt.toISOString(),
          id: edit.panier ? edit.panier.id : null,
          cartItems: paniersAnnonce
        }
      }
      console.log(data)
      return data
    },
    async askCartComputation ()
    {
      // await this.sendForm(true)
    },
    askCartChange (ask, item) {
      console.log('askCartChange')
      console.log(ask)
      this.edit = ask
      this.lastChangeItem = item
      this.$emit('changeCartItems', ask, item)
      // this.askCartComputation()
    }
  }
}
</script>

<template>
  <div class="rental-status">
    <div class="alert mb-4" :class="notificationAlertClasses" role="alert">
      <div class="alert-icon">
        <span v-if="step !== 'LOADING'" :class="notificationIconClasses"></span>
        {{notificationStatus}}
      </div>
      <span></span>
    </div>
  </div>
  <div class="askTemplate" :class="templateClasses">
    <div>
      <div v-if="isOwnerAndTenantNotCertified" style="width: 100%">
        <div class="certification-badge">
          <span class="certification-icon"></span>
          <span class="certification-description">
                    {{ $t('certification-badge.description-vue', {firstname: ask.locataire.prenom }) }}
                </span>
          <a class="read-more" target="_blank" href="https://support.lightyshare.com/article/195-un-membre-non-certifie-ma-envoye-une-demande-de-location">
            {{ $t('certification-badge.more-content') }}
            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="arrow-right">
                <path id="Icon" d="M2.7085 7.00065H10.2918M10.2918 7.00065L6.50016 3.20898M10.2918 7.00065L6.50016 10.7923" stroke="#080025" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>
          </a>
        </div>
      </div>
      <h3 class="multisteps-form__title">Dates de location</h3>
      <div class="votre-demande">
        <div class="date-box depart-date">
          <div class="date">
            <h5 style="text-transform: capitalize;" v-if="startDateInfo">{{ startDateInfo.day }} <br> <span>{{ startDateInfo.date }}</span><br><span>{{ startDateInfo.month }}</span></h5>
          </div>
          <h4>
            {{$t('infos.date.start-label')}} <br> <span>{{$t('infos.date.start')}}</span>
          </h4>
        </div>
        <div class="arrow">
          <i class="fas fa-chevron-right"></i>
        </div>
        <div class="date-box retour-date">
          <div class="date">
            <h5 style="text-transform: capitalize;" v-if="endDateInfo">{{ endDateInfo.day }} <br> <span>{{ endDateInfo.date }}</span><br><span>{{ endDateInfo.month }}</span></h5>
          </div>
          <h4>
            {{$t('infos.date.return-label')}}<br> <span>{{$t('infos.date.return')}}</span>
          </h4>
        </div>
      </div>
      <div v-if="userType === 'owner' && ask && ask.remboursement && ask.remboursement.montantRemboursementProprietaire">
        <b>{{$t('infos.penalty.label')}}</b>
        <p>{{$t('infos.penalty.refund')}} {{ ask.remboursement.montantRemboursementProprietaire - ask.remboursement.montantFraisProprietaire }} € TTC</p>
        <p>{{$t('infos.penalty.choice')}}</p>
        <div class="row col-sm-12">
          <button  class="btn submit" id="penalite_appliquer" value="Appliquer" name="appliquer">
            {{$t('infos.penalty.btnApply')}}
          </button>
          <button class="btn submit" id="penalite_rembourser" value="Rembourser" name="cancel">
            {{$t('infos.penalty.btnRefund')}}
          </button>
        </div>
        <el-alert title="Warning alert" type="warning" show-icon />
        <p>{{$t('infos.penalty.alert')}}</p>
      </div>
      <div v-if="secondaryActionsButtons.length" class="contrat-de-location secondary-actions-buttons-list">
        <h3 class="multisteps-form__title">{{ $t('infos.actions.label') }}</h3>
        <p>
          {{ $t('infos.actions.subtitle') }}
        </p>
        <div class="button">
          <a v-for="actionLink in secondaryActionsButtons" :href="actionLink.href" :class="actionLink.classes" @click="actionLink.onClick">{{actionLink.label}}</a>
        </div>
      </div>
      <hr class="only-desktop">
      <AskResume class="only-phone" :user-type="userType" :ask="ask"></AskResume>
      <div>
        <h3 class="multisteps-form__title">{{ projectTitle }}</h3>
        <div class="text show-more-height">
          <p v-if="ask" v-html="ask.description"></p>
        </div>
      </div>
      <AskCart ref="askCart" v-model="edit" :ask="ask" :modifiable="isUpdatable" @change="askCartChange" :available-cart-quantity="availableCartQuantity">
      </AskCart>

      <div class="a-savior-section">
        <h3 class="multisteps-form__title">
          {{$t('infos.cancel.label')}}
        </h3>
        <p><strong>
          {{$t('infos.cancel.subtitle')}}
        </strong><br>
          <p class="mb-0" v-html="cancelInfoText">
          </p>
        </p>
      </div>
    </div>
    <div>
      <AskResume class="only-desktop" :user-type="userType" :ask="ask">
        <template #after>
          <el-button v-for="actionButton in actionButtonsFormatted" plain :loading="!!actionButton.loading" size="large" class="fullwith" :class="actionButton.classes" :type="actionButton.type" @click.prevent="actionButton.onClick">{{ actionButton.label }}</el-button>
        </template>
      </AskResume>

    </div>
    <div class="ask-primary-action only-phone">
      <el-button v-for="actionButton in actionButtonsFormatted" plain size="large" class="fullwith" :class="actionButton.classes" :type="actionButton.type" @click.prevent="actionButton.onClick" :loading="!!actionButton.loading">{{ actionButton.label }}</el-button>
    </div>
    <Teleport to="body">
      <ModalLayout :show="cancelModal || cancelOwnerModalNoCartItemValid" @close="closeCancelModal" :header="false" :footer="true" modal-class="full-width-modal">
        <template #body>
          <el-scrollbar ref="scrollbarRef" max-height="80vh" class="remove-modal-padding">
            <h5 class="modal-title mb-2">{{ $t('modals.cancel.' + userType + '.title') }}</h5>
            <CancelEdit :userType="userType" :ask="ask" @change="onCancelChange">
            </CancelEdit>
          </el-scrollbar>
        </template>
        <template #footer>
          <el-button class="btn" plain size="large" @click="closeCancelModal">{{$t('modals.cancel.' + userType + '.close')}}</el-button>
          <el-button plain class="btn btn-save" size="large" @click="sendCancelation" :loading="formLoading">{{$t('modals.cancel.' + userType + '.btnCancel')}}</el-button>
        </template>
      </ModalLayout>
    </Teleport>
    <el-dialog v-model="noCertificationPopup" class="no-certification-popup" :close-on-click-modal="true">
      <div class="no-certification-popup-inner">
        <div class="welcome-model-header">
          <h3><img :src="asset('img/svg/thumb-icon.svg')">{{$t('certified.title')}}</h3>
        </div>
        <div class="welcome-model-content">
          <p>
            {{$t('certified.description')}}
          </p>
          <div class="welcome-model-btns">
            <a :href="path('lighty_share_lighty_share_account_settings')  + '#section-certification'" class="btn btn-primary">
              {{$t('certified.button.accept')}}
            </a>
            <a href="#" @click="closeNoCertifiedPopup" class="btn btn-close-model">
              {{$t('certified.button.later')}}
            </a>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>

</style>