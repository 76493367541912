<script>
import UserTicketCard from "~/modules/user/components/UserTicketCard.vue";

export default {
  name: "AskResume",
  components: {
      UserTicketCard
  },
  props: {
    userType: {
      type: String,
      validator: function (value) {
        return ['owner', 'tenant'].includes(value)
      },
    },
    ask: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  computed: {
    user () {
      if (!this.ask) {
        return null
      }
      if (this.userType === 'owner') {
        return this.ask.locataire
      } else {
        return this.ask.proprietaire
      }
    },
    cart ()
    {
      return this.ask ? this.ask.panier : null
    },
    pricesInfos ()
    {
      const lines = []
      if (this.ask && this.ask.remboursement && this.ask.remboursement.montantRemboursementProprietaire) {

        let montantRemboursementProprietaireTTC = this.ask.remboursement.montantRemboursementProprietaire
        if (this.userType === 'owner') {
          montantRemboursementProprietaireTTC = this.ask.remboursement.montantRemboursementProprietaire - this.ask.remboursement.montantFraisProprietaire
        }
        const montantRemboursementProprietaireHT = montantRemboursementProprietaireTTC / 1.2
        // const montantFraisProprietaire = this.ask.remboursement.montantFraisProprietaire
        lines.push({
          label: this.userType === 'tenant' ? "Pénalités d'annulation" : "Frais d'annulation",
          price: this.$currencyFormat.format(montantRemboursementProprietaireHT)
        })
      } else if (this.ask && this.ask.remboursement && !this.ask.remboursement.montantRemboursementProprietaire) {
      } else if (this.cart) {
        lines.push({
          label: this.$currencyFormat.format(this.cart.prixNormal / this.cart.nbr_jour_utilisation) + ' x ' + this.cart.nbr_jour_utilisation + ' jour(s)',
          price: this.$currencyFormat.format(this.cart.prixNormal)
        })
        if ((parseFloat(this.cart.prixNormal) - parseFloat(this.cart.prixLocationHt)) > 0) {
          lines.push({
            label: 'Dégressivité',
            labelClasses: 'success-color',
            price: '-' + this.$currencyFormat.format(parseFloat(this.cart.prixNormal) - parseFloat(this.cart.prixLocationHt)),
            priceClasses: 'success-color',
          })
        }
        if (this.ask.price_before_promo) {
          lines.push({
            label: 'Code promo',
            labelClasses: 'success-color',
            price: '-' + this.$currencyFormat.format(parseFloat(this.ask.price_before_promo) - parseFloat(this.ask.prix_location_ht)),
            priceClasses: 'success-color',
          })
        }
        if (parseFloat(this.cart.serviceTotalHt)) {
          lines.push({
            label: 'Service',
            price: this.$currencyFormat.format(this.cart.serviceTotalHt)
          })
        }
        if (this.userType === 'tenant') {
          if (parseFloat(this.cart.assuranceHt)) {
            lines.push({
              label: 'Assurance',
              price: this.$currencyFormat.format(this.cart.assuranceHt)
            })
          }
          lines.push({
            label: 'Frais de service',
            price: this.$currencyFormat.format(this.ask.frais_service_ht)
          })

        }

      }

      return lines.map((line) => {
        return {
          labelClasses: '',
          priceClasses: '',
          ...line,
        }
      })
    },
    total ()
    {
      const lines = []
      if (!this.ask) {
        return lines
      }
      let totalHTPrice = this.ask.prix_total_ht
      let TVAPrice = this.ask.prix_total_ttc - this.ask.prix_total_ht
      let totalTTCPrice = this.ask.prix_total_ttc
      let refund = 0
      if (this.userType === 'owner') {
        totalHTPrice = this.ask.ownerRevenuHT
        TVAPrice = this.ask.ownerRevenuTTC - this.ask.ownerRevenuHT
        totalTTCPrice = this.ask.ownerRevenuTTC
      }
      if (this.ask.remboursement && this.ask.remboursement.montantRemboursementProprietaire) {

        totalTTCPrice = this.ask.remboursement.montantRemboursementProprietaire
        if (this.userType === 'owner') {
          totalTTCPrice = this.ask.remboursement.montantRemboursementProprietaire - this.ask.remboursement.montantFraisProprietaire
        }
        totalHTPrice = totalTTCPrice / 1.2
        TVAPrice = totalTTCPrice - totalHTPrice
      }
      if (this.userType === 'tenant') {
        lines.push({
          label: 'Total HT',
          price: this.$currencyFormat.format(totalHTPrice)
        }, {
          label: 'TVA',
          price: this.$currencyFormat.format(TVAPrice)
        }, {
          label: 'Total TTC',
          price: this.$currencyFormat.format(totalTTCPrice),
          priceClasses: 'total-bold',
          labelClasses: 'price-total-bold'
        })
        if (this.ask && this.ask.remboursement && this.ask.paidAtUnix && this.ask.prix_total_ttc - this.ask.remboursement.montantRemboursementLocataire) {
          lines.push({
            label: 'Remboursement',
            price: this.$currencyFormat.format(this.ask.remboursement.montantRemboursementLocataire),
            priceClasses: 'total-bold',
            labelClasses: 'price-total-bold'
          })
        }
      } else {
        lines.push({
          label: 'Revenu HT',
          price: this.$currencyFormat.format(totalHTPrice)
        }, {
          label: 'TVA',
          price: this.$currencyFormat.format(TVAPrice)
        }, {
          label: 'Revenu TTC',
          price: this.$currencyFormat.format(totalTTCPrice),
          priceClasses: 'price-total-bold',
          labelClasses: 'total-bold'
        })
      }
      return lines.map((line) => {
        return {
          labelClasses: '',
          priceClasses: '',
          ...line,
        }
      })
    }
  },
}
</script>

<template>
  <div class="ask-resume-card">
    <UserTicketCard :user="user"></UserTicketCard>
    <hr>
    <div class="price-resume-panel">
      <div v-if="pricesInfos.length">
        <div v-for="info in pricesInfos" class="panel">
          <span :class="info.labelClasses">{{ info.label }}</span>
          <span :class="info.priceClasses">{{ info.price }}</span>
        </div>
        <hr>
      </div>
      <div v-for="t in total" class="panel">
        <span :class="t.labelClasses">{{ t.label }}</span>
        <span :class="t.priceClasses">{{ t.price }}</span>
      </div>
    </div>
    <slot name="after">
    </slot>
  </div>
</template>

<style scoped>

</style>